import { useAuth0 } from "@auth0/auth0-react";
import { RotatingLines } from "react-loader-spinner";
import axiosClient from "../../services/apiClient";
import { addAccessTokenInterceptor } from "../../services/apiClient";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Button from "@mui/material/Button";

//import DatePicker from "./datePicker";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TabPanel from "../../components/TabPanel";

import UnitEventsTable from "./unitEventsTable";
import { buildEventsAllBuildings } from "./unitEventsUtils";
import BuildingEventsTable from "./buildingEventsTable";

type Building = {
  LatLong: [number, number];
  buildingId: string;
  name: string;
};

function Loader() {
  return (
    <RotatingLines
      strokeColor="grey"
      strokeWidth="5"
      animationDuration="0.75"
      width="96"
      visible={true}
    />
  );
}

export default function Insights() {
  const [level, setLevel] = useState(0);
  const {isAuthenticated, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const [buildingsEventData, setBuildingsEventData] = useState<any[]>([]);
  const [building, setBuilding] = useState<Building>({
    LatLong: [0, 0],
    buildingId: "",
    name: "",
  });

  const [tenantName,setTenantName] = useState(null);
  const [AreBuildingsLoading, setAreBuildingsLoading] = useState(false);

  const handleChange = (newValue: number) => {
    setLevel(newValue);
  };

  useEffect(() => {
    if (
      buildingsEventData.length > 0 
    ) {
      setAreBuildingsLoading(false);
    }
  }, [buildingsEventData]);

  
  useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently);
    
    const fetchTenantName = async () => {
      let response = await axiosClient.get("tenants/current");
      console.log(response.data.name)
      setTenantName(response.data.name);
    };
    fetchTenantName();
    const fetchIdTokenClaims = async () => {
      try {
        const claims = await getIdTokenClaims();
//        console.log(claims)
        const tenantId = claims?.['https://cooktopsafety.com/TenantId']; 
        // Now you have the tenantId, store it or use it as needed
//        console.log("Tenant ID:", tenantId);
      } catch (error) {
        console.error("Error fetching ID token claims:", error);
        // Handle the error appropriately
      }
    };

    fetchIdTokenClaims(); // Call the function to fetch the claims

    setAreBuildingsLoading(true);
    const fetchProperties = async () => {
      let response = await axiosClient.get("sensor-groups?pageSize=10");
      return response.data.data;
    };
    const processPropertyData = async () => {
      let propertyData = await fetchProperties();
      const events = buildEventsAllBuildings(propertyData);
      setBuildingsEventData(events);
    };
    processPropertyData();
//  }, [isAuthenticated,getAccessTokenSilently]);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (building.buildingId !== "") {
      setLevel(1);
    }
  }, [building]);

  const handleLoadUnitsData = (selectedBuilding: string) => {
    if (selectedBuilding === building.buildingId) {
      // No need to set the building again, we are returning to the same building
      setLevel(1);
      return;
    }
    for (let index = 0; index < buildingsEventData.length; index++) {
      if (buildingsEventData[index].buildingId == selectedBuilding) {
        setBuilding(buildingsEventData[index]);
        break;
      }
    }
  };

  return (
    <div className="container mx-auto mt-32 mb-60 overflow-x-scroll ">
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}></Box>
        {/*        <DateRangeSelector setInterval={handleIntervalChange} />*/}
        {level === 1 && (
          <div className="inline-block float-right ml-9 mt-3">
            <Button
              variant="outlined"
              startIcon={<ArrowBackIosIcon />}
              onClick={() => handleChange(0)}
            >
              {" "}
              Properties
            </Button>
          </div>
        )}
        <TabPanel value={level} index={0}>
          {
            <>
              {AreBuildingsLoading ? (
                <Loader />
              ) : (
                <BuildingEventsTable
                  data={buildingsEventData}
                  loadUnitsData={handleLoadUnitsData}
                  organization={tenantName}
                />
              )}
            </>
          }
        </TabPanel>
        <TabPanel value={level} index={1}>
          {
            <>
              {
                <UnitEventsTable
                  building={building}
                />
              }
            </>
          }
        </TabPanel>
      </Box>
    </div>
  );
}
