import axiosClient from "../../services/apiClient";

 const groupPageSize = 10;

export async function getCtsConfig(groupid) {
  let GroupId = groupid;
  try {
    const response = await axiosClient.get(`ctsconfig/${GroupId}`);
    if (response.status != 200) {
      throw new Error(`HTTP error! status: ${response.status}`);
    } else {
      return await response.data;
    }
  } catch (err) {
    throw new Error("Error fetching data:", err.message);
  }
}

export async function getSensorSessions(sensorName, sensorPageSize) {
  try {
    const response = await axiosClient.get(
      `telemetry/${sensorName}/sessions?pageSize=${sensorPageSize}`
    );
    if (response.status != 200) {
      throw new Error(`HTTP error! status: ${response.status}`);
    } else {
      //            console.log(`response: ${JSON.stringify(response.data, null, 2)}`);
      return (await response).data;
    }
  } catch (err) {
    throw new Error("Error fetching data:", err.message);
  }
}

export async function getSensorConnectivity(sensorName, sensorPageSize) {
    try {
      const response = await axiosClient.get(
        `telemetry/${sensorName}/connectivity?pageSize=${sensorPageSize}`
      );
      if (response.status != 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        //            console.log(`response: ${JSON.stringify(response.data, null, 2)}`);
        return (await response).data;
      }
    } catch (err) {
      throw new Error("Error fetching data:", err.message);
    }
}
  
export async function getSensorStateChanges(sensorName, sensorPageSize) {
    try {
      const response = await axiosClient.get(
        `telemetry/${sensorName}/statechange?pageSize=${sensorPageSize}`
      );
      if (response.status != 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        //            console.log(`response: ${JSON.stringify(response.data, null, 2)}`);
        return (await response).data;
      }
    } catch (err) {
      throw new Error("Error fetching data:", err.message);
    }
}
  

export  async function getPCOStateChanges(sensorName, sensorPageSize) {
    try {
      const response = await axiosClient.get(
        `telemetry/${sensorName}/PCOstatechange?pageSize=${sensorPageSize}`
      );
      if (response.status != 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        //            console.log(`response: ${JSON.stringify(response.data, null, 2)}`);
        return (await response).data;
      }
    } catch (err) {
      throw new Error("Error fetching data:", err.message);
    }
}
  
export async function getBootInfo(sensorName, sensorPageSize) {
    try {
      const response = await axiosClient.get(
        `telemetry/${sensorName}/bootinfo?pageSize=${sensorPageSize}`
      );
      if (response.status != 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        //            console.log(`response: ${JSON.stringify(response.data, null, 2)}`);
        return (await response).data;
      }
    } catch (err) {
      throw new Error("Error fetching data:", err.message);
    }
}
  
export async function getSensors(groupid) {
    try {
      const response = await axiosClient.get(
        `sensor-groups/${groupid}/sensors?pageSize=${groupPageSize}`
      );
      if (response.status != 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        //            console.log(`response: ${JSON.stringify(response.data, null, 2)}`);
        return (await response).data;
      }
    } catch (err) {
      throw new Error("Error fetching data:", err.message);
    }
  }

export const calcTotalSessionsDuration = (sessions) => {
  let totalDuration = 0;
  sessions.forEach((session) => {
    totalDuration += session.durationSeconds;
  });
  return Math.round(totalDuration * 100) / 100;
};

export const calcSessionPresenceDuration = (sessions) => {
  let presenceDuration = 0;
  sessions.forEach((session) => {
    presenceDuration += session.presence.durationSeconds;
  });
  return Math.round(presenceDuration * 100) / 100;
};

export const calcSessionExposedDuration = (sessions) => {
  let exposedDuration = 0;
  sessions.forEach((session) => {
    exposedDuration += session.exposedBurner.durationSeconds;
  });
  return Math.round(exposedDuration * 100) / 100;
};

export const calcSessionsTooHotPotDuration = (sessions) => {
  let tooHotPotDuration = 0;
  sessions.forEach((session) => {
    tooHotPotDuration += session.tooHotPot.durationSeconds;
  });
  return Math.round(tooHotPotDuration * 100) / 100;
};

export const calcSessionPresenceCount = (sessions) => {
  let presenceCount = 0;
  sessions.forEach((session) => {
    presenceCount += session.presence.count;
  });
  return presenceCount;
};

export const calcSessionFireCount = (sessions) => {
  let fireCount = 0;
  sessions.forEach((session) => {
    fireCount += session.fire.count;
  });
  return fireCount;
};

export const calcAlarms = (sessions) => {
  let alarms = 0;
  sessions.forEach((session) => {
    alarms +=
      session.tooHotPot.count +
      session.exposedBurner.count +
      session.fire.count;
  });
  return alarms;
};

export function buildEventsAllBuildings(propertyData) {
  var output = [];
  for (
    var buildingIndex = 0;
    buildingIndex < propertyData.length;
    buildingIndex++
  ) {
    let oneBuildingSum = {
      buildingId: propertyData[buildingIndex].id, // buildingId
      name: propertyData[buildingIndex].name, // name
      LatLong: [
        propertyData[buildingIndex].location.latitude,
        propertyData[buildingIndex].location.longitude,
      ],
    };

    output = output.concat(oneBuildingSum);
  }
  return output;
}
