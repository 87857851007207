import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import "./login.css";


const LoginComponent = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const [searchParams, setSearchParams] = useSearchParams();
  const invitation = searchParams.get("invitation");
  const organization = searchParams.get("organization");

  useEffect(() => {
    if (!isAuthenticated) {
      loginWithRedirect({
        ...(invitation && {
          authorizationParams: { invitation, organization },
        }),
      });
    }
  }, [loginWithRedirect, isAuthenticated]);

  return <div className="login-container"></div>;
};

export default LoginComponent;
