import {useTable, useSortBy, usePagination, useFilters, useGlobalFilter,} from "react-table";
import axiosClient from "../../services/apiClient";
import {
  getCtsConfig,
  getSensorSessions,
  getSensorConnectivity,
  getSensorStateChanges,
  getPCOStateChanges,
  getBootInfo,
  getSensors,
  calcTotalSessionsDuration,
  calcSessionPresenceDuration,
  calcSessionExposedDuration,
  calcSessionsTooHotPotDuration,
  calcSessionPresenceCount,
  calcSessionFireCount,
  calcAlarms,
} from "./unitEventsUtils";
import React, { useState, useEffect } from "react";
import { GlobalFilter } from "./buildingEventsTable";
import Map from "../../components/SelectableMap";
import { LuCirclePower } from "react-icons/lu"; // power icon
import { IoMdRefreshCircle } from "react-icons/io"; // refresh icon
import { FaUnlock,FaLock } from "react-icons/fa"; // unlock icon
import { IoCloudOffline } from "react-icons/io5"; // offline icon
import { HiOutlineStatusOnline } from "react-icons/hi"; // online icon
import { ImFire } from "react-icons/im";
import { IconContext } from "react-icons";
import { PiCookingPotFill } from "react-icons/pi";
import { IoIosFlash } from "react-icons/io";
import { RotatingLines } from "react-loader-spinner";
import {
    useQuery,
    useQueries,
} from "@tanstack/react-query";


function Loader() {
  return (
    <RotatingLines
      strokeColor="grey"
      strokeWidth="5"
      animationDuration="0.75"
      width="96"
      visible={true}
    />
  );
}


export default function UnitEventsTable(props) {
  const [TableData, setTableData] = useState([]);
  const [tableUpdateFlag, setTableUpdateFlag] = useState(false);
  const [buttonStates, setButtonStates] = useState([
    { isPressed: false, isHovered: false }, // State for button 1
    { isPressed: false, isHovered: false }, // State for button 2
    { isPressed: false, isHovered: false }, // State for button 3
    // ... more buttons
  ]);
    const [loading, setLoading] = useState(true);

    const sensorPageSize = 100;
    let refetchInterval = 60000*60; // refetch queries every hour

  const {
    data: CtsConfig,
    error: configError,
    isPending: configLoading,
  } = useQuery({
    queryKey: ["config", props.building.buildingId, tableUpdateFlag],
    queryFn: async () => await getCtsConfig(props.building.buildingId),
    refetchInterval: refetchInterval,
  });

  const {
    data: sensorResults,
    error: sensorsError,
    isPending: sensorsLoading,
  } = useQuery({
    queryKey: ["sensors", props.building.buildingId, tableUpdateFlag],
    queryFn: async () => await getSensors(props.building.buildingId),
    refetchInterval: refetchInterval,
  });

  const sensors = !sensorsLoading && !sensorsError ? sensorResults.data : [];

  const { sessions, sensorSessionLoading, sensorSessionError } = useQueries({
    queries: sensors.map((sensor) => {
      return {
        queryKey: ["sessions", sensor.name, sensorPageSize, tableUpdateFlag],
        queryFn: async () =>
          await getSensorSessions(sensor.name, sensorPageSize),
        enabled: !!sensor.name,
        refetchInterval: refetchInterval,
      };
    }),
      combine: (results) => {
          return {
            sessions: results
              ? results.map((result) => (result.data ? result.data.data : []))
              : [],
            sensorSessionLoading: results
              ? results.some((result) => result.isPending)
              : true,
            sensorSessionError: results
              ? results.some((result) => result.isError)
              : false,
          };
    },
    enabled: sensors?.length > 0,
  });

  const { connectivity, connectivityLoading, connectivityError} = useQueries({
    queries: sensors.map((sensor) => {
      return {
        queryKey: [
          "connectivity",
          sensor.name,
          sensorPageSize,
          tableUpdateFlag,
        ],
        queryFn: async () =>
          await getSensorConnectivity(sensor.name, sensorPageSize),
        enabled: !!sensor.name,
        refetchInterval: refetchInterval,
      };
    }),
      combine: (results) => {
          return {
            connectivity: results
              ? results.map((result) => (result.data ? result.data.data : []))
              : [],
            connectivityLoading: results
              ? results.some((result) => result.isPending)
              : true,
            connectivityError: results
              ? results.some((result) => result.isError)
              : false,
          };
      },
    enabled: sensors?.length > 0,
  });

  const { statechanges, statechangesLoading, statechangesError} = useQueries({
    queries: sensors.map((sensor) => {
      return {
        queryKey: [
          "statechanges",
          sensor.name,
          sensorPageSize,
          tableUpdateFlag,
        ],
        queryFn: async () =>
          await getSensorStateChanges(sensor.name, sensorPageSize),
        enabled: !!sensor.name,
        refetchInterval: refetchInterval,
      };
    }),
    combine: (results) => {
      return {
        statechanges: results
          ? results.map((result) => (result.data ? result.data.data : []))
          : [],
        statechangesLoading: results
          ? results.some((result) => result.isPending)
          : true,
        statechangesError: results
          ? results.some((result) => result.isError)
          : false,
      };
    },
    enabled: sensors?.length > 0,
  });

  const { PCOstatechanges, PCOstatechangesLoading, PCOstatechangesError} = useQueries({
    queries: sensors.map((sensor) => {
      return {
        queryKey: [
          "PCOstatechanges",
          sensor.name,
          sensorPageSize,
          tableUpdateFlag,
        ],
        queryFn: async () =>
          await getPCOStateChanges(sensor.name, sensorPageSize),
        enabled: !!sensor.name,
        refetchInterval: refetchInterval,
      };
    }),
    combine: (results) => {
      return {
        PCOstatechanges: results
          ? results.map((result) => result.data? result.data.data: [])
          : [],
        PCOstatechangesLoading: results
          ? results.some((result) => result.isPending)
          : true,
        PCOstatechangesError: results
          ? results.some((result) => result.isError)
          : false,
      };
    },
    enabled: sensors?.length > 0,
  });

if (PCOstatechangesError) {
  console.error("Error fetching PCO state changes:", PCOstatechangesError);
}

const { bootinfo, bootinfoLoading, bootinfoError } = useQueries({
  queries: sensors.map((sensor) => {
    return {
      queryKey: ["bootinfo", sensor.name, sensorPageSize, tableUpdateFlag],
      queryFn: async () => await getBootInfo(sensor.name, sensorPageSize),
      enabled: !!sensor.name,
      refetchInterval: refetchInterval,
    };
  }),
  combine: (results) => {
    return {
      bootinfo: results
        ? results.map((result) => (result.data ? result.data.data : []))
        : [],
      bootinfoLoading: results
        ? results.some((result) => result.isPending)
        : true,
      bootinfoError: results ? results.some((result) => result.isError) : false,
    };
  },
  enabled: sensors?.length > 0,
});

if (bootinfoError) {
  console.error("Error fetching boot info:", bootinfoError);
}


  const error =
    configError ||
    sensorsError ||
    sensorSessionError ||
    connectivityError ||
    statechangesError ||
    PCOstatechangesError ||
      bootinfoError;

    useEffect(() => {
      if (configLoading || sensorsLoading || sensorSessionLoading || connectivityLoading
          || statechangesLoading || PCOstatechangesLoading || bootinfoLoading) {
        return;
      }
        
      let result = [];
      for (let i = 0; i < sensors.length; i++) {
        const sensor = sensors[i];
        const sensorSessions = sessions[i];
        const sensorConnectivity = connectivity[i];
        const sensorStateChanges = statechanges[i];
        const sensorPCOStateChanges = PCOstatechanges[i];
        const sensorBootInfo = bootinfo[i];

        let status = "disconnected";
        if (sensorConnectivity.length > 0 && sensorConnectivity[0]) {
          const thestate = sensorConnectivity[0];
          status = thestate.eventType;
        }

        status = status === "connected" ? "Online" : "Offline";

        let stoveStatus = "NULL";

        if (sensorStateChanges.length > 0 && sensorStateChanges[0]) {
          let thestate = sensorStateChanges[0];
          if (
            thestate.currentStateEnum == 6 ||
            thestate.currentStateEnum == 9
          ) {
            stoveStatus = "OFF";
          } else {
            stoveStatus = "ON";
          }
        }

        let isStoveInUse = "NULL";

        if (sensorStateChanges.length > 0 && sensorStateChanges[0]) {
          let thestate = sensorStateChanges[0];
          if (
            thestate.prevStateEnum == 0 &&
            (thestate.currentStateEnum == 1 || // monitoring
              thestate.currentStateEnum == 2 || // warning
              thestate.currentStateEnum == 3 || // fault state
              thestate.currentStateEnum == 4)
          ) {
            isStoveInUse = "YES";
          } else {
            isStoveInUse = "NO";
          }
        }

        let isPCOconnected = "NULL";
        let stoveCurrent = "NULL";
        let PCOFWVersion = "NULL";
        let IsStoveRemoteUnlocked = "NULL";
        //        console.log(`pco state change: ${JSON.stringify(PCOstateChangePerSensor, null, 2)}`);
        if (sensorPCOStateChanges.length > 0 && sensorPCOStateChanges[0]) {
          let thestate = sensorPCOStateChanges[0];
          stoveCurrent = `${thestate.currentCurrentmA}`;
          PCOFWVersion = `${thestate.currentFWverMajor}.${thestate.currentFWverMinor}.${thestate.currentFWverPatch}`;
          IsStoveRemoteUnlocked = thestate.currentIsStoveRemoteUnlocked
            ? "UNLOCKED"
            : "LOCKED";
          //          console.log(`the IsStoveRemoteUnlocked is: ${IsStoveRemoteUnlocked}`)
          if (thestate.currentUnitConnectedStatus == 1) {
            isPCOconnected = "YES";
          } else {
            isPCOconnected = "NO";
          }
        }

        let SCSFWVersion = "NULL";

        if (sensorBootInfo.length > 0 && sensorBootInfo[0]) {
          let thestate = sensorBootInfo[0];
          SCSFWVersion = thestate.firmwareVersion;
        }

        const totalDuration = calcTotalSessionsDuration(sensorSessions);

        let entry = {
          UnitNumber: sensor.displayName,
          SensorID: sensor.name,
          Status: status,
          stoveStatus: stoveStatus,
          isStoveInUse: isStoveInUse,
          isPCOconnected: isPCOconnected,
          stoveCurrent: stoveCurrent,
          PCOFWVersion: PCOFWVersion,
          SCSFWVersion: SCSFWVersion,
          IsStoveRemoteUnlocked: IsStoveRemoteUnlocked,
          NumberOfSessions: sensorSessions.length,
          AvgSessionLength:
            Math.round((totalDuration / sensorSessions.length) * 100) / 100,
          MaxSessionIntensity: 0,
          Warnings: 0,
          Alarms: calcAlarms(sensorSessions),
          AlarmResponses: 0,
          PresenceCounter: calcSessionPresenceCount(sensorSessions),
          PresenceRatio:
            Math.round(
              (calcSessionPresenceDuration(sensorSessions) / totalDuration) *
                100
            ) / 100,
          ExposedBurnerAccTime: calcSessionExposedDuration(sensorSessions),
          TooHotAccTime: calcSessionsTooHotPotDuration(sensorSessions),
          Fire: calcSessionFireCount(sensorSessions),
        };
        result.push(entry);
      }

        setTableData(result);
        setLoading(false);
    }, [
      tableUpdateFlag,
      configLoading,
      sensorsLoading,
      sensorSessionLoading,
      connectivityLoading,
      statechangesLoading,
      PCOstatechangesLoading,
      bootinfoLoading,
    ]); 

  const center = props.building.LatLong;

  const handleRowClick = (row) => {
    // props.loadBuildingData(row.buildingId);
  };

  var columns = React.useMemo(
    () => [
      {
        Header: "Unit",
        accessor: "UnitNumber", // accessor is the "key" in the data
        filter: "fuzzyText",
        isVisible: true,
      },
      {
        Header: "Sensor ID",
        accessor: "SensorID", // accessor is the "key" in the data
        filter: "fuzzyText",
        isVisible: true,
      },
      {
        Header: "Sensor Connection",
        accessor: "Status", // accessor is the "key" in the data
        Cell: ({ value }) => {
          return (
            <div
              style={{
                display: 'inline-flex', // Important for inline alignment
                alignItems: 'center',    // Vertically center content
                gap: '8px',            // Space between image and text
                color: value === "Online" ? "green" : "red",
                fontWeight: "bold",
              }}
            >
              {value==="Online"?<HiOutlineStatusOnline style={{ width: '24px', height: '24px' }} />:<IoCloudOffline style={{ width: '24px', height: '24px' }}/>}
              {value}
            </div>
          );
        },
        isVisible: true,
      },
      {
        Header: "PCO connection",
        accessor: "isPCOconnected", // accessor is the "key" in the data
        Cell: ({ row }) => {
          return (
            <div 
            style={{
              display: 'inline-flex', // Important for inline alignment
              alignItems: 'center',    // Vertically center content
              gap: '8px',            // Space between image and text
              color: row.original.Status === "Online"?(row.original.isPCOconnected === "YES" ? "green" : "red"):"red",
              fontWeight: "bold",
            }}
            >
            {row.original.Status==="Online"?(row.original.isPCOconnected==="YES"?<HiOutlineStatusOnline style={{ width: '24px', height: '24px' }}/>:<IoCloudOffline style={{ width: '24px', height: '24px' }}/>):<IoCloudOffline style={{ width: '24px', height: '24px' }}/>}
            {row.original.Status === "Online"? (row.original.isPCOconnected==="YES"?"Online":"Offline"): "Offline"}
            </div>
          );
        },
        isVisible: CtsConfig?.CTSConfiguration["Dashboard.unitEventsPage"]?.Table?.Columns?.IsPCOconnected?.isVisible,
      },
      {
          Header: "Stove Power",
          accessor: "TurnOffStove", // accessor is the "key" in the data
          Cell: ({ row }) => {
            const [isPressed, setIsPressed] = React.useState(false);
            const [isHovered, setIsHovered] = React.useState(false);

            const buttonStyles = {
              backgroundColor: row.original.Status === "Online"? (row.original.isPCOconnected==="YES"?(row.original.stoveStatus === "ON"? "green": "red"):"green"): "grey",
              color: "white",
              display: 'inline-flex', // Important for inline alignment
              alignItems: 'center',    // Vertically center content
              gap: '8px',            // Space between image and text
              fontWeight: "bold",
              border: "none",
              padding: "5px 10px",
              cursor: "pointer",
              borderRadius: "5px",
//              display: "inline-block",
              width: '100%',
              transition: 'all 0.1s ease', // Add transition here
              boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.9), 1px 1px 0px rgba(255, 255, 255, 0.2) inset"
             };

            const activeStyles = {
              transform: 'translateY(2px)',
              boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.2) inset', // Or adjust as needed
            };

            const hoverStyles = {
              boxShadow: "5px 5px 10px rgba(0, 0, 0, 1), 2px 2px 0px rgba(255, 255, 255, 0.3) inset",
              backgroundColor: row.original.Status === "Online"? (row.original.stoveStatus === "ON"? "#45a049": "#c53c2f"): "grey", // Darker green/red on hover
           };            
            return (

              <td style={{ padding: '0', border: 'none', overflow: 'visible' }}> {/* Style the cell */}
                <button
                    disabled={row.original.Status!=="Online" || row.original.stoveStatus!== "ON" || row.original.isPCOconnected === "NO"}
                  //disabled={false}
                  style={{
                    ...buttonStyles,
                    ...(isPressed?activeStyles:{}),
                    ...(isHovered ? hoverStyles : {}),
                    cursor:`${row.original.Status==="Online"? (row.original.isPCOconnected==="YES"?(row.original.stoveStatus==="ON"? "pointer":"not-allowed" ):"not-allowed"):"not-allowed"}`
                  }}
                  onMouseDown={() => setIsPressed(true)}
                  onMouseUp={() => setIsPressed(false)}
                  onMouseLeave={() => {setIsPressed(false); setIsHovered(false);}}
                  onMouseEnter={() => setIsHovered(true)}   // Set isHovered to true on mouse enter
                  onClick={() => handleTurnOffStove(row.original.SensorID)}
                >

                  <LuCirclePower style={{ width: '24px', height: '24px' }}/>
                  {row.original.Status === "Online"? row.original.stoveStatus: "N/A"}
                </button>
              </td>
            );
          },
          isVisible: true,
      },
      {
        Header: "Unlock Stove",
        accessor: "StoveRemoteUnlock", // accessor is the "key" in the data
        Cell: ({ row }) => {
          const [isPressed, setIsPressed] = React.useState(false);
          const [isHovered, setIsHovered] = React.useState(false);
          const buttonStyles = {
            backgroundColor: row.original.Status === "Online"? (row.original.isPCOconnected==="YES"? (row.original.stoveStatus=== "OFF"?(row.original.IsStoveRemoteUnlocked==="UNLOCKED"?"green":"#FF0000"): "grey"):"grey"):"grey",
            display: 'inline-flex', // Important for inline alignment
            alignItems: 'center',    // Vertically center content
            gap: '8px',            // Space between image and text
            color: "white",
            fontWeight: "bold",
            border: "none",
            padding: "5px 10px",
            cursor: "pointer",
            borderRadius: "5px",
            width: '100%',
            transition: 'all 0.1s ease', // Add transition here
            boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.9), 1px 1px 0px rgba(255, 255, 255, 0.2) inset"
           };

          const activeStyles = {
            transform: 'translateY(2px)',
            boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.2) inset', // Or adjust as needed
          };

          const hoverStyles = {
            boxShadow: "5px 5px 10px rgba(0, 0, 0, 1), 2px 2px 0px rgba(255, 255, 255, 0.3) inset",
            backgroundColor: row.original.Status === "Online"? (row.original.isPCOconnected==="YES"? (row.original.stoveStatus=== "OFF"?(row.original.IsStoveRemoteUnlocked==="UNLOCKED"?"#45a049":"#b32000"): "grey"):"grey"):"grey"
            
         };            
//         console.log(`row: ${row.original.IsStoveRemoteUnlocked}`);
          return (
            <td style={{ padding: '0', border: 'none', overflow: 'visible' }}> {/* Style the cell */}
              <button
                disabled={row.original.Status!=="Online" || row.original.isPCOconnected !== "YES" || row.original.stoveStatus!== "OFF" || row.original.IsStoveRemoteUnlocked==="UNLOCKED"}
           //     disabled={false}
                style={{
                  ...buttonStyles,
                  ...(isPressed?activeStyles:{}),
                  ...(isHovered ? hoverStyles : {}),
                  cursor: `${row.original.Status === "Online"? (row.original.isPCOconnected==="YES"? (row.original.stoveStatus=== "OFF"?(row.original.IsStoveRemoteUnlocked==="LOCKED"?'pointer':'not-allowed'):'not-allowed'):'not-allowed'):'not-allowed'}`,
                }}
                onMouseDown={() => setIsPressed(true)}
                onMouseUp={() => setIsPressed(false)}
                onMouseLeave={() => {setIsPressed(false); setIsHovered(false);}}
                onMouseEnter={() => setIsHovered(true)}   // Set isHovered to true on mouse enter
                onClick={() => handleStoveRemoteUnlock(row.original.SensorID)}
              >
                {row.original.Status === "Online"? (row.original.isPCOconnected==="YES"? (row.original.stoveStatus=== "OFF"?(row.original.IsStoveRemoteUnlocked==="UNLOCKED"?<FaUnlock style={{ width: '24px', height: '24px' }}/>:<FaLock style={{ width: '24px', height: '24px' }}/>):null):null):null}
                {row.original.Status === "Online"? (row.original.isPCOconnected==="YES"? (row.original.stoveStatus=== "OFF"?row.original.IsStoveRemoteUnlocked:"N/A"):"N/A"):"N/A"}
              </button>
            </td>
          );
        },
        isVisible: true,
      },
      {
        Header: "Stove Current (mA)",
        accessor: "stoveCurrent", // accessor is the "key" in the data
        Cell: ({ row }) => {
//          console.log(`row: ${JSON.stringify(row.original, null, 2)}`);
          return (
            <div >
            {row.original.Status === "Online"? (row.original.isPCOconnected==="YES"?row.original.stoveCurrent:"N/A"): "N/A"}

            </div>
          );
        },
        isVisible: true,
      },
      {
        Header: "Number of Sessions",
        accessor: "NumberOfSessions", // accessor is the "key" in the data
        Cell: ({ row }) => {
          return (
            <div >
            {row.original.Status === "Online"? row.original.NumberOfSessions: "N/A"}
            </div>
          );
        },
        isVisible: true,
      },
      {
        Header: "Alarms",
        accessor: "Alarms", // accessor is the "key" in the data
        Cell: ({ row }) => {
          return (
            <div >
            {row.original.Status === "Online"? row.original.Alarms: "N/A"}
            </div>
          );
        },
        isVisible: true,
      },
    ],
    [tableUpdateFlag,CtsConfig]
  );

  async function handleTurnOffStove(sensorid) {
    //    console.log(`Turning off stove for unit: ${sensorid}`);
        axiosClient.post(`sensors/${sensorid}/shutdownstove`);
        await delay(5000); // Wait for the delay
        setTableUpdateFlag(!tableUpdateFlag); // Example: Toggle table update flag    
  }

  async function handleStoveRemoteUnlock(sensorid){
      axiosClient.post(`sensors/${sensorid}/unlockstove`);
      await delay(5000); // Wait for 2 seconds
      setTableUpdateFlag(!tableUpdateFlag); 
  }



  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      //fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );
//   console.log(`tabledata = ${JSON.stringify(TableData, null, 2)}`);

const thedata = React.useMemo(() => TableData,);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    preGlobalFilteredRows,
    setGlobalFilter,
    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, hiddenColumns },
    //Added TS ignore Outdated ts support in for this library
    // @ts-ignore
  } = useTable(
    {
      columns,
      data: TableData,
      initialState: {
        hiddenColumns: columns.filter((column) => !column.isVisible).map((column) => column.accessor),
        sortBy: [{ id: "UnitNumber" }],
        pageIndex: 0,
      },
      filterTypes,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const buttonStyles = {
    backgroundColor:"grey",
    display: 'inline-flex', // Important for inline alignment
    alignItems: 'center',    // Vertically center content
    gap: '8px',            // Space between image and text
    color: "white",
    fontWeight: "bold",
    border: "none",
    padding: "5px 10px",
    cursor: "pointer",
    borderRadius: "5px",
//    display: "inline-block",
//    width: '100%',
    transition: 'all 0.1s ease', // Add transition here
    boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.9), 1px 1px 0px rgba(255, 255, 255, 0.2) inset"
   };

  const activeStyles = {
    transform: 'translateY(2px)',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.2) inset', // Or adjust as needed
  };

  const hoverStyles = {
    boxShadow: "5px 5px 10px rgba(0, 0, 0, 1), 2px 2px 0px rgba(255, 255, 255, 0.3) inset",
    backgroundColor: "#45a049",
    
 };

 const handleMouseDown = (index) => {
  setButtonStates(prevStates => {
    const newStates = [...prevStates];
    newStates[index].isPressed = true;
    return newStates;
  });
};

const handleMouseUp = (index) => {
  setButtonStates(prevStates => {
    const newStates = [...prevStates];
    newStates[index].isPressed = false;
    return newStates;
  });
};

const handleMouseEnter = (index) => {
  setButtonStates(prevStates => {
      const newStates = [...prevStates];
      newStates[index].isHovered = true;
      return newStates;
    });
};

const handleMouseLeave = (index) => {
  setButtonStates(prevStates => {
      const newStates = [...prevStates];
      newStates[index].isPressed = false;
      newStates[index].isHovered = false;
      return newStates;
    });
};

const delay = (ms) => new Promise(res => setTimeout(res, ms)); // Helper function


const handleClick = async (label) => {
  switch (label) {
    case "refresh":
      // Refresh logic  
      console.log("Refreshing...");
      setLoading(true);
      await delay(1000); // Wait for the delay
      setTableUpdateFlag(!tableUpdateFlag); // Example: Toggle table update flag
      break;
    case 'Turn off all stoves':
      // Turn off all stoves logic
      console.log("Turning off all stoves...");
      for(const item of TableData)
      {
        if(item.Status === "Online" && item.isPCOconnected ==="YES" && item.stoveStatus ==="ON")
        {
          handleTurnOffStove(item.SensorID);

        }
      }
      // ... your code to turn off stoves
      break;
    case 'Unlock all stoves':
      for(const item of TableData)
        {
          if(item.Status === "Online" && item.isPCOconnected ==="YES" && item.stoveStatus ==="OFF" && item.IsStoveRemoteUnlocked==="LOCKED")
          {
            handleStoveRemoteUnlock(item.SensorID);
          }
        }
      // Unlock all stoves logic
      console.log("Unlocking all stoves...");
      // ... your code to unlock stoves
      break;
    default:
      console.log("Unknown button clicked:", label);
      break;
  }
};

const buttons = [
  { label: 'refresh', 
    text: <span style={{ verticalAlign: 'top' }}>Refresh</span>, 
    img:<IoMdRefreshCircle style={{ width: '36px', height: '36px' }} />
  },
  { label: 'Turn off all stoves',
    text: <span style={{ verticalAlign: 'middle' }}>Turn off all stoves</span>,
    img: <LuCirclePower style={{ width: '36px', height: '36px' }} /> },
  { label: 'Unlock all stoves',
    text: <span style={{ verticalAlign: 'middle' }}>Unlock all stoves</span>,
    img:<FaUnlock style={{ width: '36px', height: '36px' }}/> },
];
    
const totalAlarms = TableData.reduce((acc, item) => acc + (item.Status === "Offline" ? 0 : item.Alarms), 0);
const totalOffline = TableData.reduce(
  (acc, item) => acc + (item.Status === "Offline" ? 1 : 0),
  0
);
const totalStovesOn = TableData.reduce(
  (acc, item) =>
    acc +
    (item.stoveStatus == "ON" &&
    item.PCOFWVersion !== "NULL" &&
    item.Status === "Online"
      ? 1
      : 0),
  0
);
    
const totalCurrentUsage = TableData.reduce(
  (acc, item) =>
    acc +
    (item.PCOFWVersion !== "NULL" &&
    item.Status === "Online" &&
    item.stoveCurrent !== "NULL"
      ? Number(item.stoveCurrent)
      : 0),
  0
);


console.log(`CTSConfig: ${JSON.stringify(CtsConfig,null,2)}`);
  if (!loading && CtsConfig && TableData && TableData.length > 0 && columns && columns.length > 0) {
 
  return (
    <>
      <div className="container m-auto mt-6">
        <h1 className="text-2xl">Property: {props.building.name}</h1>
        <div className="container-infobox">
          <div className="info-textbox">
            <IconContext.Provider
              value={{ color: "#E25822", className: "global-class-name" }}
            >
              <ImFire className="icon" />
            </IconContext.Provider>
            <span>{totalAlarms}</span> <h2>Alarms </h2>
          </div>
          <div className="info-textbox">
            <IconContext.Provider
              value={{ color: "grey", className: "global-class-name" }}
            >
              <IoCloudOffline className="icon" />
            </IconContext.Provider>
            <span>{totalOffline}</span> <h2>Offline </h2>
          </div>
          <div className="info-textbox">
            <IconContext.Provider
              value={{ color: "#26496c", className: "global-class-name" }}
            >
              <PiCookingPotFill className="icon" />
            </IconContext.Provider>
            <span>{totalStovesOn}</span> <h2>Stoves ON </h2>
          </div>
          <div className="info-textbox">
            <IconContext.Provider
              value={{ color: "orange", className: "global-class-name" }}
            >
              <IoIosFlash className="icon" />
            </IconContext.Provider>
            <span>{totalCurrentUsage}</span> <h2>mA Current </h2>
          </div>
        </div>
        <div style={{ display: "flex", gap: "10px", height: "40px" }}>
          {buttons.map((button, index) => (
            <button
              key={index} // Important: Add a unique key for each button
              disabled={false}
              style={{
                ...buttonStyles,
                ...(buttonStates[index].isPressed ? activeStyles : {}),
                ...(buttonStates[index].isHovered ? hoverStyles : {}),
              }}
              onMouseDown={() => handleMouseDown(index)}
              onMouseUp={() => handleMouseUp(index)}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={() => handleMouseLeave(index)}
              onClick={() => handleClick(button.label)}
            >
              {button.img}
              {button.text} 
            </button>
          ))}
        </div>

        <div style={{ height: '20px' }} />
        <div className="container-layout">
          <div style={{ flexGrow: 0 }}>

          <table
            className="drop-shadow-lg"
            {...getTableProps()}
            style={{ border: "solid 1px black" }}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    return (
                      <th
                        // @ts-ignore
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        style={{
                          borderBottom: "solid 3px black",
                          background: "white",
                          color: "black",
                          fontWeight: "bold",
                          padding: "10px",
                        }}
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " 🔽"
                              : " 🔼"
                            : ""}
                        </span>
                      </th>
                    );
                  })}
                </tr>
              ))}
              <tr>
                <th
                  style={{
                    textAlign: "left",
                  }}
                >
                  <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    setGlobalFilter={setGlobalFilter}
                  />
                </th>
              </tr>
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr
                    onClick={() => handleRowClick(row.original)}
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          style={{
                            padding: "10px",
                            border: "solid 1px gray",
                            background: i % 2 === 0 ? "#EEEEEE" : "white",
                          }}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>

          {/* 
          Pagination can be built however you'd like. 
          This is just a very basic UI implementation:
        */}


          <div className="pagination">
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {"<<"}
            </button>{" "}
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {"<"}
            </button>{" "}
            <>
              {Array.from({ length: 5 }, (_, i) => {
                const pageNumber = pageIndex + 1 + i;
                const isDisabled = pageNumber > pageOptions.length;

                return (
                  <button
                    key={pageNumber}
                    disabled={isDisabled}
                    onClick={() => !isDisabled && gotoPage(pageNumber - 1)}
                  >
                    {pageNumber}
                  </button>
                );
              })}
            </>
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              {">"}
            </button>{" "}
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </button>{" "}
            {/* <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select> */}
          </div>
          {/* END OF Pagination */}
        </div>
        <Map data={[props.building]} center={center} />
        </div>
        </div>
      </>
    );
  } else if (loading) {
    return <Loader/>;
  } else {
    return <p>Error</p>;
  }
}