import { useEffect } from "react";
import Navbar from "./components/Navbar/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import LoginComponent from "./pages/Login/login";
import Insights from "./pages/Events";
import "./index.css";
import ScrollToTop from "./ScrollToTop";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { RotatingLines } from "react-loader-spinner";
import {
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";

function Loader() {
  return (
    <RotatingLines
      strokeColor="grey"
      strokeWidth="5"
      animationDuration="0.75"
      width="96"
      visible={true}
    />
  );
}

const ProtectedRoute = ({ component: Component }) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated) {
      loginWithRedirect();
    }
  }, [isAuthenticated, loginWithRedirect]);

  return isAuthenticated ? <Component /> : null;
};

const queryClient = new QueryClient();

function App() {
  const { isAuthenticated, isLoading, error } = useAuth0();
  if (isLoading)
    return (
      <div className="spinner">
        <Loader />
      </div>
    );
  if (error) return <div>Oops... {error.message}</div>;
    return (
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Router>
            <ScrollToTop />
            <Navbar />
            <Routes>
              <Route
                path="/"
                element={isAuthenticated ? <Insights /> : <LoginComponent />}
              />
              <Route path="/login" element={<LoginComponent />} />
              <Route
                path="/insights"
                element={<ProtectedRoute component={Insights} />}
              />
            </Routes>
          </Router>
        </LocalizationProvider>
      </QueryClientProvider>
    );
}

export default App;
